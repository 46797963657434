<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} category` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="category.title"
                                outlined
                                dense
                                :error="$v.category.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.category.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label>
                                Description <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="category.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.category.description.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="category.is_active" :label="category.is_active ? 'Active' : 'Inactive'"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="cancelForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn
                                    v-if="checkIsAccessible('gate-pass', 'create')"
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import CategoryService from "@/core/services/category/CategoryService";

const category = new CategoryService();

export default {
    validations: {
        category: {
            title: { required },
            description: {},
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            editorConfig: {
                versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            category: {
                title: '',
                description: '',
                is_active: true
            },
        };
    },
    methods: {
        cancelForm(){
            this.resetForm()
            this.hideModal()
        },
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.getCategory(id)
            }else{
                this.resetForm()
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getCategory(id) {
            category
            .show(id)
            .then(response => {
                this.category = response.data.category;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.category.$touch();
            if (this.$v.category.$error) {
                setTimeout(() => {
                    this.$v.category.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateCategory();
                } else {
                    this.createCategory(this.category);
                }
            }
        },
        updateCategory() {
            this.isBusy = true;
            category
            .update(this.category.id, this.category)
            .then(response => {
                this.isBusy = false;
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
                this.$snotify.success("Information updated");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createCategory(fd) {
            this.isBusy = true;
            category
            .store(this.category)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.category.$reset();
            this.edit = false;
            this.category = {
                title: '',
                description: '',
                is_active: true
            };
        }
    }
}
</script>
